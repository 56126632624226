import { getPluginId } from '@grafana-incident-app/utils/pluginId';

import plugin from '../package.json';

export const CURRENT_VERSION = plugin.version;
export const PLUGIN_ROOT = `/a/${getPluginId()}`;
export const IS_DEV = process.env.NODE_ENV === 'development';
export const SLACK_CLIENT_ID = '2888862884.2048386887543';
export const PREVIEW_INTEGRATIONS = ['grate.msTeams'];

export const TOP_EMOJI = [
  { emoji: '⭐' },
  { emoji: '🎯' },
  { emoji: '🤖' },
  { emoji: '👍' },
  { emoji: '👎' },
  { emoji: '🎉' },
  { emoji: '🔥' },
];
